.DragAndDrop {
  &__dnd-container {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  &__table {
    border: 1px solid #d9e1ff;
    border-top: none;
  }

  &__sizes_block {
    display: flex;
    justify-content: flex-end;
    background: #fafafa;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #d9e1ff;
    border-bottom: none;
    padding: 10px 20px 0;
  }

  &__sizes_buttons {
    display: flex;
    gap: 15px;
    background: #fafafa;
    width: 100%;
  }
}
