.Advertising {
  &__table_image {
    width: 30px;
    height: 30px;

    img {
      object-fit: cover;
      width: 32px;
      height: 32px;
      border-radius: 6px;
    }
  }

  &__table_title {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 12px;
  }

  &__table_description {
    margin-bottom: 0;
    display: -webkit-box;
    max-width: 100%;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
  }

  &__table_category {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;

    img {
      width: 18px;
      filter: invert(65%) ;
    }

    p {
      margin: 0;
    }
  }

  &__table_date,
  &__table_order {
    font-size: 12px ;
    margin: 0;
  }
}
