.AdvertisingHeader {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;

  &__title {
    font-size: 22px;
    font-weight: 500;
    color: #121417;
    margin-bottom: 20px;
  }

  &__filter {
    width: 225px;
  }

  &__option {
    display: flex;
    align-items: center;
  }

  &__category_icon {
    filter: invert(100%);
    width: 15px;
    margin-right: 10px;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }


  .ant-select-selection-placeholder {
    color: black;
    font-size: 11px;
  }

  .ant-select-selector {
    background: #F7F8FF !important;
    border: 1px solid #E4E8FF !important;
  }

  &__select_block {
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    border: none;
    outline: none;
    background: transparent;
  }

  &__header_block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &__flex {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  &__filter_title {
    text-transform: uppercase;
    color: #A1A1C3;
    font-size: 12px;
    margin-bottom: 8px;
  }

  &__mode_select {
    display: flex;
    background: #F7F8FF;
    padding: 3px;
    border-radius: 8px;
    gap: 10px;
    font-size: 12px;
    border: 1px solid #E4E8FF;

    button {
      color: #535A89;
      background: #F7F8FF;
      padding: 6px 18px;
      width: 50%;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .mode_button_active {
    background: white;
    color: #007AFF;
  }

  .single_child {
    justify-content: flex-end;
    margin-top: 32px;
  }
}
