.AdvertCard {
  &__card_advert {
    position: relative;
    width: 345px;
    border-radius: 8px;
    margin-right: 5px;
    margin-bottom: 17px;
    > .ant-card-head {
      position: absolute;
      border-bottom: none;
    }
  }

  &__block_card {
    position: relative;

    .block_card_text {
      .card_title {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 700;
        color: #130e2f;
      }

      .card_text {
        font-weight: 400;
        font-size: 11px;
        color: #8b94a6;
      }

      .block_run {
        width: 224px;
        overflow-x: scroll;
        display: flex;
        margin-top: 2px;

        .block_text {
          padding-right: 8px;

          .run_title {
            font-size: 9px;
            font-weight: 200;
            color: #a9aed1;
            margin-bottom: 0;
            margin-right: 10px;
            white-space: nowrap;
          }

          .run_text {
            font-size: 11px;
            font-weight: 400;
            color: #130e2f;
            white-space: nowrap;
          }
        }
      }

      .block_run::-webkit-scrollbar {
        width: 12px;
        height: 4px;
      }

      .block_run::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 2px;
      }

      .block_run::-webkit-scrollbar-thumb {
        -webkit-border-radius: 2px;
        border-radius: 2px;
        background: #007aff;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }

      .block_run::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(255, 0, 0, 0.4);
      }

      .block_card {
        height: 68px;
      }
    }
  }

  &__bottom_block {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__category, &__order {
    color: #007aff;
    background: #F6F8FF;
    border: 1px solid #EAF4FF;
    font-weight: bold;
  }

  &__order {
    display: inline-block;
    padding: 6px 13px;
    border-radius: 50%;
  }

  &__category {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 5px 15px;
    border-radius: 18px;

    img {
      width: 18px;
      filter: invert(60%) sepia(60%) saturate(2596%) hue-rotate(199deg) brightness(100%) contrast(100%);
    }

    p, span {
      margin: 0;
      font-size: 12px;
      font-weight: bold;
    }
  }

  &__star {
    background: #59a4ff;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
    cursor: pointer;
  }
}
